/* General Reset */
body, h1, h2, h3, p {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* Fixed Icons for WhatsApp and Call */
.fixed-icons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000; /* Ensures the icons appear above other elements */
}

.fixed-icons .icon {
  font-size: 24px;
  color: #25D366; /* WhatsApp green color */
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease;
}

.fixed-icons .icon.call-icon {
  color: #34B7F1; /* Call icon color */
}

.fixed-icons .icon:hover {
  transform: scale(1.1);
}
