/* Main container styling */
.technical-support-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9;
}

/* Header styling */
.tech-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 40px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
}

.tech-header h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 15px;
}

.tech-header p {
  font-size: 1.2em;
  margin-bottom: 25px;
}

/* Section styling for each part */
.tech-content, .tech-benefits, .tech-process, .tech-cta {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  padding: 30px;
  color: #444;
}

/* Section headings */
.tech-content h2, .tech-benefits h2, .tech-process h2, .tech-cta h2 {
  color: #007bff;
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 20px;
}

/* Paragraph styling */
.tech-content p, .tech-benefits p, .tech-process p, .tech-cta p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

/* Grid layout for images and text */
.tech-content, .tech-benefits, .tech-process {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
}

.tech-content img, .tech-benefits img, .tech-process img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tech-content, .tech-benefits, .tech-process {
    grid-template-columns: 1fr;
  }

  .tech-header h1 {
    font-size: 2em;
  }

  .cta-button {
    padding: 10px 25px;
    font-size: 1em;
  }

  .tech-content img, .tech-benefits img, .tech-process img {
    max-height: 250px;
  }
}

/* CTA section styling */
.tech-cta {
  background-color: #007bff;
  color: white;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  margin-top: 50px;
}

.tech-cta p {
  font-size: 1.2em;
  margin-bottom: 25px;
}

/* CTA button styling */
.cta-button {
  background-color: white;
  color: #007bff;
  padding: 15px 40px;
  font-size: 1.1em;
  font-weight: bold;
  border-radius: 25px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-button:hover {
  background-color: #e6f0ff;
  transform: translateY(-2px);
}

.cta-button:active {
  transform: translateY(2px);
}
