/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f9f9f9;
  color: rgba(0, 0, 0, 0.8);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-logo img {
  width: 50px;
  height: auto;
  transition: transform 0.3s;
}

.navbar-logo img:hover {
  transform: scale(1.1);
}

.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.navbar-links li {
  display: inline;
}

.navbar-links a {
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s;
}

.navbar-links a:hover {
  color: #00aaff;
  text-decoration: underline;
}

/* Dropdown menu for Services */
.services-menu {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  list-style: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}

.dropdown-menu li {
  margin: 5px 0;
}

.dropdown-item {
  text-decoration: none;
  color: #333;
  padding: 5px;
  display: block;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
  color: #007BFF;
}

/* Show the dropdown when hovered */
.services-menu:hover .dropdown-menu {
  display: block;
}

/* Responsive design */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
    padding-left: 0;
  }

  .navbar-logo img {
    width: 40px;
  }

  .navbar-links a {
    font-size: 1rem;
  }

  .navbar-links {
    width: 100%;
    padding-top: 10px;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px 15px;
  }

  .navbar-logo img {
    width: 35px;
  }

  .navbar-links a {
    font-size: 0.9rem;
  }
}
