.footer {
    padding: 20px;
    text-align: center;
    color: white;
    background-size: cover;
    background-position: center;
  }
  
  .footer p {
    font-size: 1rem;
    margin: 0;
  }
  
  .social-links {
    margin-top: 10px;
  }
  
  .social-links a {
    color: #f1f1f1;
    text-decoration: none;
    margin: 0 5px;
    font-weight: bold;
  }
  
  .social-links a:hover {
    color: #007BFF;
  }
  