.legal {
    padding: 20px; /* Padding around the section */
    background-color: #f9f9f9; /* Light background */
    max-width: 100%; /* Allow full width */
    margin: 0; /* Remove margins for full width */
    border-radius: 0; /* Remove border radius for a flush look */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .legal h2 {
    text-align: center; /* Center the heading */
    color: #007BFF; /* Primary color for the heading */
    font-size: 2rem; /* Font size for the heading */
    margin-bottom: 20px; /* Space below the heading */
  }
  
  .legal p {
    font-size: 1rem; /* Font size for the paragraph */
    color: #333; /* Dark text for readability */
    line-height: 1.6; /* Improved line spacing for readability */
    text-align: center; /* Center-align the text */
  }
  
  .legal a {
    color: #007BFF; /* Link color */
    text-decoration: none; /* Remove underline */
    font-weight: 500; /* Slightly bolder links */
  }
  
  .legal a:hover {
    text-decoration: underline; /* Underline links on hover for clarity */
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .legal h2 {
      font-size: 1.8rem; /* Smaller heading size for mobile */
    }
  
    .legal p {
      font-size: 0.9rem; /* Smaller paragraph size for mobile */
    }
  }
  