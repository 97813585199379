/* careers.css */

.careers {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.careers-header h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
}

.careers-header p {
  font-size: 1.1em;
  color: #666;
  margin-bottom: 20px;
}

.vacancies {
  list-style-type: none;
  padding: 0;
  font-size: 1.1em;
}

.vacancies li {
  padding: 10px;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 8px 0;
  transition: transform 0.2s, box-shadow 0.2s;
}

.vacancies li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: #f1f1f1;
}
