/* Main container styling */
.digital-marketing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #333;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
  }
  
  /* Header styling */
  .digital-header {
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 8px;
  }
  
  .digital-header h1 {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .digital-header p {
    font-size: 1.2em;
    margin-bottom: 25px;
  }
  
  /* Section styling for each part */
  .digital-content, .digital-benefits, .digital-process, .digital-cta {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    padding: 30px;
    color: #444;
  }
  
  /* Section headings */
  .digital-content h2, .digital-benefits h2, .digital-process h2, .digital-cta h2 {
    color: #007bff;
    font-size: 2em;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  /* Paragraph styling */
  .digital-content p, .digital-benefits p, .digital-process p, .digital-cta p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
  }
  
  /* Grid layout for images and text */
  .digital-content, .digital-benefits, .digital-process {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
  }
  
  .digital-content img, .digital-benefits img, .digital-process img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .digital-content, .digital-benefits, .digital-process {
      grid-template-columns: 1fr;
    }
  
    .digital-header h1 {
      font-size: 2em;
    }
  
    .cta-button {
      padding: 10px 25px;
      font-size: 1em;
    }
  
    .digital-content img, .digital-benefits img, .digital-process img {
      max-height: 250px;
    }
  }
  
  /* CTA section styling */
  .digital-cta {
    background-color: #007bff;
    color: white;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin-top: 50px;
  }
  
  .digital-cta p {
    font-size: 1.2em;
    margin-bottom: 25px;
  }
  
  /* CTA button styling */
  .cta-button {
    background-color: white;
    color: #007bff;
    padding: 15px 40px;
    font-size: 1.1em;
    font-weight: bold;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #e6f0ff;
    transform: translateY(-2px);
  }
  
  .cta-button:active {
    transform: translateY(2px);
  }
  