.faq-container {
  display: flex; /* Flex container for image and FAQ */
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  gap: 20px;
}

.faq-image {
  flex: 0 0 40%; /* 40% width for the image */
}

.faq-image img {
  width: 100%; /* Make image take full width of container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 6px; /* Optional: rounded corners */
}

.faq {
  flex: 0 0 60%; /* 60% width for FAQ section */
}

.faq h2 {
  text-align: center;
  color: #007BFF;
  font-size: 2rem;
  margin-bottom: 20px;
}

.faq-item {
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  margin: 10px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
}

.faq-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.faq-item h3 {
  font-size: 1.4rem;
  color: #333;
  margin-bottom: 5px;
}

.faq-item p {
  font-size: 1rem;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .faq-container {
      flex-direction: column; /* Stack image and FAQ on smaller screens */
  }
  
  .faq-image, .faq {
      flex: 1 1 100%; /* Full width for both sections */
  }

  .faq h2 {
      font-size: 1.8rem;
  }

  .faq-item h3 {
      font-size: 1.2rem;
  }

  .faq-item p {
      font-size: 0.9rem;
  }
}
