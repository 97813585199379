/* Container styling */
.company-overview {
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Title styling */
.title {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #261dab;
}

/* Layout styling for overview content */
.overview-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.company-image {
  flex: 1 1 200px;
  max-width: 300px;
}
.text-content {
  flex: 1 1 200px;
  max-width: 1000px;
}


.company-image {
  width: 20%;
  height: 10%;
  border-radius: 8px;
}

.company-image:hover {
  transform: scale(1.05);
}

/* Text content styling */
.text-content {
  text-align: left;
  color: #555;
  display: flex;
  flex-direction: column;
}

.overview-text {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 9px;
}

/* Icon section styling */
.icon-section {
  display: flex;
  justify-content: start;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.icon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.icon-card:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 1.8rem;
  color: #261dab;
  margin-bottom: 8px;
}

.icon-card p {
  font-size: 0.85rem;
  color: #333;
  margin: 0;
  font-weight: 500;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .overview-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content, .company-image {
    max-width: 100%;  /* Ensures full width on smaller screens */
  }

  .text-content {
    align-items: center;
    text-align: center;
  }

  .icon-section {
    justify-content: center;
  }
}
