/* Container styling */
.contact-us {
  display: flex;
  justify-content: center;
  padding: 50px;
  background-color: #f4f4f9;
}

.contact-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

/* Left section: Form */
.contact-form {
  flex: 1;
  padding: 40px;
  background: #ffffff;
}

.contact-form h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.contact-form label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #555;
  transition: border-color 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.contact-form button {
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Right section: Contact Info */
.contact-info {
  flex: 1;
  padding: 40px;
  background: linear-gradient(to right, #007BFF, #00c853);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.contact-info h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-info p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.contact-info a {
  color: #ffeb3b;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.contact-info a:hover {
  color: #ffd600;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-form, .contact-info {
    padding: 30px;
  }
}

@media (max-width: 480px) {
  .contact-form h2, .contact-info h2 {
    font-size: 1.8rem;
  }

  .contact-info p {
    font-size: 1rem;
  }

  .contact-form input, .contact-form textarea, .contact-form button {
    font-size: 0.9rem;
  }
}
