.services {
  padding: 60px 20px;
  text-align: center;
  background-color: #f4f7fa;
}

.services h2 {
  font-size: 2.8rem;
  margin-bottom: 40px;
  color: #333;
  font-weight: 600;
}

.service-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.nav-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 12px;
  font-size: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  transition: background-color 0.3s, transform 0.2s;
}

.nav-button:hover {
  background-color: #0056b3;
  transform: scale(1.1);
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
}

.service {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: left;
}

.service:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
}

.service h3 {
  font-size: 1.8rem;
  color: #007BFF;
  margin-bottom: 10px;
  font-weight: 500;
}

.service p {
  color: #555;
  font-size: 1rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-list {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .services h2 {
    font-size: 2.2rem;
  }

  .nav-button {
    font-size: 1.4rem;
    padding: 10px;
    width: 40px;
    height: 40px;
  }
}
