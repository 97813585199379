.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    position: relative;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #007BFF;
    margin: 10px 0;
    text-decoration: none;
  }
  
  .contact-icon {
    margin-right: 10px;
    font-size: 1.5rem;
  }


   .hero {
    text-align: center;
    padding: 50px 20px;
    background-image: linear-gradient(to right, rgba(0, 123, 255, 0.8), rgba(0, 250, 33, 0.8)), url('../../assets/Background1.jpeg');
    background-size: cover;
    background-position: center;
  }
  
  .hero h1, .hero h2, .hero p {
    color: white;
  }
  
  .hero-buttons .primary-button,
  .hero-buttons .secondary-button {
    padding: 10px 20px;
    font-size: 1rem;
    margin: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .primary-button {
    background-color: #007BFF;
    color: white;
  }
  
  .secondary-button {
    background-color: #6c757d;
    color: white;
  }
  

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    position: relative;
    text-align: center;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #007BFF;
    margin: 10px 0;
    text-decoration: none;
  }
  
  .contact-icon {
    margin-right: 10px;
    font-size: 1.5rem;
  }
  